
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .banner {
    @apply bg-blue-700 text-white py-16 px-80 text-center text-3xl;
  }
  .custom-card {
    @apply rounded-xl py-6 px-6;
    box-shadow: 4px 4px 20px rgba(122, 122, 122, 0.3);
  }
  .card-header {
    @apply text-2xl text-center font-bold;
  }
  .input-label {
    @apply py-2 font-bold;
  }
  .btn-primary {
    @apply bg-taxeve-primary-blue text-white rounded-lg;
  }

  .icon-color {
    @apply dark:text-white text-black;
  }

  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 13px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .navbar {
    @apply z-40 bg-white dark:bg-[#393E46];
  }

  .page {
    @apply pt-16 px-20 min-h-[100vh] flex flex-col;
  }

  .tb-border {
    @apply border-gray-300 dark:border-gray-500;
  }
  /* 
  .tb-border-b {
    @apply border-b;
  }

  .tb-border-r {
    @apply border-r;
  } */

  .tb-divide-x {
    @apply divide-x;
  }

  .tb-divide-y {
    @apply divide-y;
  }

  .no-page-center {
    @apply justify-start pt-[4.75rem] px-0;
  }

  .input-icon {
    @apply absolute right-[3%] top-[30%] text-xl cursor-pointer;
  }

  .disabled {
    @apply absolute z-50 top-0 left-0 w-[100%] h-[100%] bg-black opacity-20;
  }

  .link {
    @apply underline underline-offset-2 text-taxeve-primary-blue;
  }

  main {
    @apply bg-white text-black dark:bg-[#393E46] dark:text-white text-lg;
  }

  .pi-popup {
    @apply bg-white dark:bg-[#222831];
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  h4,
  h3,
  h2,
  h1 {
    @apply font-bold;
  }
  h2 {
    @apply text-xl;
  }
  h1 {
    @apply text-2xl;
  }

  input,
  textarea {
    @apply py-2 px-4 rounded-lg dark:bg-[#393E46] border border-slate-600 dark:border dark:border-slate-400;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
} */

